import { IAnswer, IMenuOption, IMultiSelectQuestionOptionsTypeConstants } from 'interfaces';
import { MULTI_SELECT_QUESTION_NA_OPTION } from 'teamble-constants';


export const getMultiSelectAnswerValue = (
  answer: IAnswer,
  multiQuestionTypeOptions: IMenuOption[],
  multiQuestionTypeConstants: IMultiSelectQuestionOptionsTypeConstants
) => {

  if (answer.family === multiQuestionTypeConstants.CUSTOM_ITEMS) {

    const choiceLabels: Record<string, string> = {};

    for (const [key, value] of Object.entries((answer as any).choicesValues || {})) {
      choiceLabels[value as string] = key;
    }

    return answer.selected_option
      ?
      answer.selected_option.text
      :
      choiceLabels[answer.value as string] || answer.value || '';
  } else {

    if (answer.isNotApplicableOption && answer.value === MULTI_SELECT_QUESTION_NA_OPTION.value) {
      return MULTI_SELECT_QUESTION_NA_OPTION.label;
    }

    if (answer?.selectedView?.value === 'dropdown') {
      answer.dropdown = true;
    }

    const builtInChoices = multiQuestionTypeOptions.find(
      (option) => option.value === answer.family
    );

    const selectedChoice = builtInChoices?.options?.find(
      (choice) => {
        return answer.dropdown
          ?
          answer.selected_option
            ?
            choice.value === answer.selected_option.value
            :
            choice.value === answer.value
          :
          answer.selected_option
            ?
            choice.label === answer.selected_option.text
            :
            choice.label === answer.value
      }
    );

    if (selectedChoice) {
      return selectedChoice.label;
    } else {
      return '';
    }

  }
};


export const getMultiSelectAnswerLabel = (answer: IAnswer): string => {

  let label = answer.label || '';

  if (answer.isBehavior && answer.behaviorTitle) {
    label = label + ' : ' + answer.behaviorTitle;
  } else if (answer.competencyName) {
    label = label + ' : ' + answer.competencyName;
  } if (answer.isKPI && answer.kpiName) {
    label = label + ' : ' + answer.kpiName;
  } else if (answer.objectiveName) {
    label = label + ' : ' + answer.objectiveName;
  }

  return label;
};