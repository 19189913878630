import { Badge, Button, Card, Col, Form, Table } from '@themesberg/react-bootstrap';
import { AnswersList, ConfirmationModal, EmptyState, Preloader } from 'components';
import { FormContext, MetaDataContext } from 'contexts';
import { getReportsByPerformanceReviewIds, submitRevieweeAnswers, useReviewTodoDetailsData } from 'data';
import { FormikHelpers, useFormik } from 'formik';
import { useApp } from 'hooks';
import {
  IAnswer,
  IMetadata,
  IMultiSelectQuestionOptionsTypeConstants,
  IReport,
  IResponse,
  IRevieweeFormValues,
  IRouteParams,
  IScoreCards,
  ISimpleMenuOption,
  ReviewTodoState
} from 'interfaces';
import moment from 'moment';
import React, { useContext, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { finalize, Subscription } from 'rxjs';
import { DATE_TIME_FORMAT, MULTI_SELECT_QUESTION_NA_OPTION } from 'teamble-constants';
import { REVIEWEE_FORM_VALIDATION_SCHEMA } from 'validation-schemas';
import ReasonModalModal from '../modals/ReasonModal';

import { getMappedRevieweeAnswers, isQuestionSameAsAbove } from '../utils/utils';
import RevieweeFormQuestion from './RevieweeFormQuestion';
import { AnswerPreview, LinkedAnswerPreview } from './ShowLinkedReviews';
import { getMultiSelectAnswerLabel } from 'utils';

interface IRevieweeFormWidgetProps {
  onRevieweeFormSubmit: (reviewId: string) => void;
  onReviewDeclined: (reviewId: string) => void;
}

interface ICompetencyHeader {
  answer: any
  answers: IAnswer[],
  index: number;
}

const QuestionHeader: React.FC<ICompetencyHeader> = ({ answer, answers = [], index }) => {
  let display = 'block';
  const choices = answer.choicesToCheck;
  const isSameQuestionAsAbove = isQuestionSameAsAbove(answers, index);

  if (answer?.selectedView?.value === 'dropdown') {
    answer.dropdown = true;
  }
  if (isSameQuestionAsAbove) {
    display = 'none';
  }

  if (answer?.selectedView?.value !== 'matrix') {
    return <></>;
  }

  return (
    <div style={{ display, top: '-20px', zIndex: 0 }} id={`comp-header-${answer?.id}`} className='bg-white'>
      {
        !answer.dropdown &&
        <Col className='w-100 d-flex'>
          <Form.Label style={{ minWidth: 300, width: 300 }} className={`fw-bolder ws-pre-line mr-2 text-start bg-white pt-2`}>{
            `${answer.label} ${answer.required ? '' : '(optional)'}:`
          }</Form.Label>
          {
            choices?.map((c: any, index: number) => (
              <Form.Label key={`${index}${c}`} style={{ minWidth: 150, width: 150 }} className={`fw-bolder ws-pre-line mr-2 text-center px-1 bg-white pt-2`}>{c?.label || ' '}</Form.Label>
            ))
          }
        </Col>
      }
    </div>
  )
}

const QuestionTitle: React.FC<ICompetencyHeader> = ({ answer, answers = [], index }) => {

  const isMatrix = answer?.selectedView?.value === 'matrix';
  const isCompetency = answer?.type === 'competency-question';
  const isSameQuestionAsAbove = isQuestionSameAsAbove(answers, index);

  let parentName = 'competencyName' as 'competencyName' | 'objectiveName';
  if (answer?.type === 'objective-question') {
    parentName = 'objectiveName';
  }

  return (
    <>
      {
        answers[index]?.[parentName] !== answers[index - 1]?.[parentName]
          ?
          <>
            {
              (isCompetency && !isMatrix && !isSameQuestionAsAbove) &&
              <h5 style={isMatrix ? { minWidth: 300, width: 300 } : {}}>{answer.label}:</h5>
            }
            {
              (answer?.isBehavior || answer?.isKPI) &&
              <>
                <h5 style={isMatrix ? { minWidth: 300, width: 300 } : {}}>{answer[parentName]}</h5>
                {
                  answer.showDescription && (answer as any).description
                    ?
                    <p style={isMatrix ? { minWidth: 300, width: 300 } : {}}> {(answer as any).description} </p>
                    :
                    null
                }
              </>
            }
          </>
          :
          null
      }
    </>
  )
}

const QuestionLabel: React.FC<{ answer: IAnswer }> = ({ answer }) => {

  const isMatrix = answer?.selectedView?.value === 'matrix';

  let labelDropdown = '';

  switch (answer.type) {
    case 'competency-question':

      labelDropdown = `${answer.label}: ${answer?.behaviorTitle || answer?.competencyName || ''} ${!isMatrix ? (answer.required ? '' : '(optional)') : ''}`;
      break;

    case 'objective-question':
      labelDropdown = `${answer.label}: ${answer?.kpiName || answer?.objectiveName || ''} ${!isMatrix ? (answer.required ? '' : '(optional)') : ''}`;
      if ((answer as any).weight) {
        labelDropdown += ` Weight: ${(answer as any).weight}%`
      }
      break;

    default:

      labelDropdown = `${answer.label} ${!isMatrix ? answer.required ? '' : '(optional)' : ''}`;
      break;
  }

  if (answer.dropdown || answer?.selectedView?.value === 'dropdown') {
    return (<Form.Label className='ws-pre-line mr-2'> {labelDropdown} </Form.Label>)
  }

  return (null);
}

export const StatementHeader: React.FC<{ answer: IAnswer }> = ({ answer }) => {

  if (answer.type === 'objective-question' && (answer as any).statementHeader) {
    return (<h3>{(answer as any).docType}</h3>)
  }

  return (null);
}

export const StatementScoreTable: React.FC<{ scoreCards: IScoreCards[], multiCampaign: boolean }> = ({
  scoreCards,
  multiCampaign
}) => {

  if (!scoreCards.length) {

    return (null)

  }

  return (

    <Card className='fill-height mb-4'>
      <Table
        style={{
          borderCollapse: 'collapse',
          borderSpacing: '6px',
        }}
      >
        <thead>
          <tr className='text-center'>
            <th> Review </th>
            <th> Score </th>
            <th> Income Statement </th>
            <th> Balance Sheet </th>
          </tr>
        </thead>
        <tbody>
          {
            scoreCards.map((scoreCard, index) => {

              return (
                <tr key={`${index}-${scoreCard.review}`}>
                  {
                    Object.values(scoreCard).map(cell => (
                      <td className='text-center'> {cell} </td>
                    ))
                  }
                </tr>
              )
            })
          }
        </tbody>
      </Table>
    </Card>
  );
}

const RevieweeFormWidget: React.FC<IRevieweeFormWidgetProps> = (
  { onRevieweeFormSubmit, onReviewDeclined }
) => {

  const submitFormSubscriptionRef = useRef<Subscription>();

  const { reviewId } = useParams<IRouteParams>();

  const metadata = useContext(MetaDataContext) as IMetadata;

  const {
    multiSelectQuestionOptionsTypes: {
      constants,
    }
  } = metadata

  const {
    isLoading,
    data: reviewTodoDetails,
    likedReviewResponse,
    onDataUpdate: onReviewTodoDetailsUpdate,
  } = useReviewTodoDetailsData(reviewId);

  const multiSelectChoicesConstants: IMultiSelectQuestionOptionsTypeConstants = constants as IMultiSelectQuestionOptionsTypeConstants;

  const [revieweeNameInitials, setRevieweeNameInitials] = React.useState<string>('');
  const [isSavingAnswers, setIsSavingAnswers] = React.useState<boolean>(false);
  const [isShowSubmitModal, setIsShowSubmitModal] = React.useState<boolean>(false);
  const [showDeclineModal, setShowDeclineModal] = React.useState<boolean>(false);
  const [isSharingReport, setIsSharingReport] = React.useState<boolean>(false);
  const isFirstAnswersSet = React.useRef<boolean>(true);

  const [questionElementRefs, setQuestionElementRefs] = React.useState<React.RefObject<HTMLDivElement>[]>([]);

  const app = useApp();


  const submitRevieweeForm = (
    values: IRevieweeFormValues,
    formikHelpers: FormikHelpers<IRevieweeFormValues> | null,
    saveOnly: boolean = false,
    isAutoSave: boolean = false,
    isDecline: boolean = false,
    declinedReason: string = '',
  ) => {

    if (!reviewId) {
      return;
    }

    submitFormSubscriptionRef.current?.unsubscribe();
    if (saveOnly) {
      setIsSavingAnswers(true);
    } else {
      formikHelpers!.setSubmitting(true);
    }

    submitFormSubscriptionRef.current = submitRevieweeAnswers(
      {
        saveOnly,
        perfReviewId: reviewId,
        blocks: getMappedRevieweeAnswers(values.answers, metadata),
        ...(isDecline && {
          declined: isDecline,
          declinedReason,
        })
      }
    )
      .pipe(
        finalize(
          () => {
            setIsShowSubmitModal(false);
            setIsSavingAnswers(false);
            formikHelpers?.setSubmitting(false);
          }
        )
      )
      .subscribe(
        (response) => {

          if (!isAutoSave) {
            toast.success(response.data.message);
          }

          if (!saveOnly) {
            onReviewTodoDetailsUpdate?.(response.data.data);
            onRevieweeFormSubmit(reviewId);
          }
          if (isDecline) {
            onReviewDeclined(reviewId);
            setShowDeclineModal(false);
          }
        }
      );

  };


  const revieweeForm = useFormik<IRevieweeFormValues>({
    initialValues: {
      answers: []
    },
    validationSchema: REVIEWEE_FORM_VALIDATION_SCHEMA,
    onSubmit: submitRevieweeForm
  });


  const scrollToFirstInvalidField = (): void => {
    if (revieweeForm.isValid) {
      return;
    }

    const firstInvalidFieldIndex =
      (revieweeForm.errors.answers as IAnswer[])?.findIndex(
        (answer) => !!answer
      );

    if (firstInvalidFieldIndex === -1) {
      return;
    }

    questionElementRefs[firstInvalidFieldIndex]?.current?.scrollIntoView();

  };


  useEffect(
    () => {

      isFirstAnswersSet.current = true;
      revieweeForm.resetForm();
      setQuestionElementRefs([]);
    },
    [reviewId]
  );


  React.useEffect(
    () => {

      if (!revieweeForm.values.answers.length) {
        return;
      }

      if (isFirstAnswersSet.current) {
        isFirstAnswersSet.current = false;

        setQuestionElementRefs(
          Array(revieweeForm.values.answers.length)
            .fill(0)
            .map(() => React.createRef<HTMLDivElement>())
        );

        return;
      }

      submitRevieweeForm(
        revieweeForm.values,
        null,
        true,
        true
      );

    },
    [revieweeForm.values]
  );


  React.useEffect(
    () => {

      if (!reviewTodoDetails || reviewTodoDetails.state === ReviewTodoState.Completed) {
        revieweeForm.setValues({
          answers: []
        });
        return;
      }

      revieweeForm.setValues({
        answers: reviewTodoDetails.answers
      });

    },
    [reviewTodoDetails]
  );


  React.useEffect(
    () => {

      if (!reviewTodoDetails) {
        setRevieweeNameInitials('');
        return;
      }

      const revieweeNames = reviewTodoDetails.revieweeName
        .split(' ')
        .map((names) => names.charAt(0).toUpperCase())
        .join('');

      setRevieweeNameInitials(revieweeNames);
    },
    [reviewTodoDetails]
  );

  const onScroll = React.useCallback(() => {
    const answers = reviewTodoDetails?.answers || [];
    for (let i = 0; i < answers?.length; i++) {
      //check div top touched top of scrolable container
      const elem = document?.getElementById(`comp-header-${answers[i]?.id}`);
      if (
        elem &&
        document!.getElementById("scrolable")!.getBoundingClientRect().y + 20 >=
        document!.getElementById(`item-${answers[i]?.id}`)!.getBoundingClientRect().y
      ) {
        if (elem) elem.style.display = "block";
      } else {
        //don't hide header if it has diffrent options
        const isSameQuestionAsAbove = isQuestionSameAsAbove(answers, i);


        if (elem && isSameQuestionAsAbove) elem.style.display = "none";
      }
    }
  }, [reviewTodoDetails])

  useEffect(() => {
    revieweeForm.values?.answers.forEach((answer) => {

      const isNotApplicableOption = (answer.type === 'competency-question' || answer.type === 'objective-question') && answer.isNotApplicableOption;
      let choices: ISimpleMenuOption[] = [];
      if (answer.family === multiSelectChoicesConstants.CUSTOM_ITEMS) {

        if (answer.dropdown || answer?.selectedView?.value === 'dropdown') {

        } else {

          const choiceLabels: Record<string, string> = {};

          for (const [key, value] of Object.entries((answer as any).choicesValues || {})) {
            choiceLabels[value as string] = key;
          }

          answer.value = choiceLabels[answer.value as string] || answer.value
        }

        const options: ISimpleMenuOption[] = answer.choices!.map(
          (choice) => ({
            label: choice,
            value: (answer as any).choicesValues ? (answer as any).choicesValues[choice] : choice
          })
        );

        choices = (
          isNotApplicableOption
            ?
            options
              ?
              [...options, MULTI_SELECT_QUESTION_NA_OPTION]
              :
              []
            :
            options
        );

      } else {

        const options = metadata.multiSelectQuestionOptionsTypes.options.find(
          (option) => option.value === answer.family
        );
        if (options?.options) {
          choices = (
            isNotApplicableOption
              ?
              [...options.options, MULTI_SELECT_QUESTION_NA_OPTION]
              :
              options.options
          );
        }
      }
      (answer as any).choicesToCheck = choices;
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [revieweeForm.values?.answers?.length])


  const shareSubmittedReviewToReviewee = (): void => {

    if (!reviewTodoDetails) {
      return;
    }

    setIsSharingReport(true);
    getReportsByPerformanceReviewIds(
      [reviewTodoDetails.id],
      false,
      false,
      [],
      reviewTodoDetails.workspaceId,
      app,
      [reviewTodoDetails.revieweeId],
      true
    )
      .pipe(
        finalize(() => setIsSharingReport(false))
      )
      .subscribe(
        (response) => {
          toast.success((response.data as IResponse<IReport[]>).message);
        }
      );

  };


  return (
    <Card className='h-100 overflow-hidden'>
      {
        isLoading
          ?
          <Preloader show={isLoading} />
          :
          reviewTodoDetails
            ?
            <>
              <Card.Header className='flex-shrink-0 d-flex justify-content-between align-items-center'>
                <Card.Title className='m-0 fw-bold'>
                  <div className='d-flex align-items-center'>
                    {
                      revieweeNameInitials &&
                      <Badge bg='primary' className='rounded-circle p-2 me-2'>
                        {revieweeNameInitials}
                      </Badge>
                    }
                    <h5 className='m-0'>
                      {reviewTodoDetails.title} | {reviewTodoDetails.revieweeName}
                    </h5>
                  </div>
                  {
                    reviewTodoDetails.closeSchedule &&
                    <p className='m-0'>
                      <strong> Due Date: </strong>
                      {moment(reviewTodoDetails.closeSchedule?.timestamp).format(DATE_TIME_FORMAT)}
                    </p>
                  }
                </Card.Title>

                {
                  reviewTodoDetails.state === ReviewTodoState.Completed && reviewTodoDetails.shareWithReviewer &&
                  <Button
                    size='sm'
                    type='button'
                    variant='outline-primary'
                    disabled={isSharingReport}
                    onClick={shareSubmittedReviewToReviewee}
                  >
                    {
                      isSharingReport
                        ?
                        <>Sharing...</>
                        :
                        <>Share with {reviewTodoDetails.revieweeName}</>
                    }
                  </Button>
                }

              </Card.Header>

              {
                reviewTodoDetails?.answers.length
                  ?
                  <Form
                    className='d-flex overflow-hidden flex-column flex-grow-1'
                    onSubmit={(e) => {
                      if (revieweeForm?.isValid) {
                        e.preventDefault()
                        setIsShowSubmitModal(true)
                      } else {
                        scrollToFirstInvalidField();
                        revieweeForm.handleSubmit(e);
                      }
                    }}
                  >
                    <FormContext.Provider value={revieweeForm}>
                      <Card.Body id='scrolable' className='overflow-y-auto flex-grow-1'
                        onScroll={onScroll}
                      >
                        {
                          reviewTodoDetails.state === ReviewTodoState.Pending
                            ?
                            <>
                              {
                                (Boolean(reviewTodoDetails.multiCampaign)) && (
                                  <Card className='d-flex justify-content-between align-items-center mb-4 p-2'>
                                    <h6> ⚠️ Please agree final score with Reviewee before submission, as final score cannot be reversed. </h6>
                                  </Card>
                                )
                              }
                              {
                                revieweeForm.values.answers.map(
                                  (answer, index) => {
                                    return (
                                      <div
                                        id={`item-${answer?.id}`}
                                        key={answer.id}
                                        className='mb-4'
                                        ref={questionElementRefs[index]}
                                      >
                                        <QuestionHeader answer={answer} answers={revieweeForm.values.answers} index={index} />
                                        {
                                          (answer as any).scoreCards && (
                                            <StatementScoreTable
                                              scoreCards={(answer as any).scoreCards}
                                              multiCampaign={Boolean(reviewTodoDetails.multiCampaign)}
                                            />
                                          )
                                        }
                                        <StatementHeader answer={answer} />
                                        <QuestionLabel answer={answer} />
                                        <QuestionTitle answer={answer} answers={revieweeForm.values.answers} index={index} />

                                        {
                                          likedReviewResponse?.[index]?.map(answer => {
                                            return (
                                              <LinkedAnswerPreview answer={answer} />
                                            )
                                          })
                                        }
                                        <RevieweeFormQuestion
                                          index={index}
                                          answer={answer}
                                          multiCampaign={Boolean(reviewTodoDetails.multiCampaign)}
                                        />
                                      </div>
                                    )
                                  }
                                )}
                            </>
                            :
                            <>

                              {
                                reviewTodoDetails.answers.map(
                                  (answer, index) => {
                                    const answerLabel = getMultiSelectAnswerLabel(answer);
                                    return (
                                      <div key={answer.id} className='mb-4'>

                                        {
                                          (answer as any).scoreCards && (
                                            <StatementScoreTable
                                              scoreCards={(answer as any).scoreCards}
                                              multiCampaign={Boolean(reviewTodoDetails.multiCampaign)}
                                            />
                                          )
                                        }
                                        <StatementHeader answer={answer} />
                                        <h5>{answerLabel}</h5>

                                        {
                                          likedReviewResponse?.[index]?.map(answer => {
                                            return (
                                              <LinkedAnswerPreview answer={answer} />
                                            )
                                          })
                                        }

                                        <AnswerPreview
                                          answer={answer}
                                          multiCampaign={Boolean(reviewTodoDetails.multiCampaign)}
                                        />

                                      </div>
                                    )
                                  }
                                )
                              }
                            </>
                          // <AnswersList answers={reviewTodoDetails.answers} />
                        }
                      </Card.Body>

                      {
                        reviewTodoDetails.state === ReviewTodoState.Pending &&
                        <Card.Footer className='d-flex justify-content-end flex-shrink-0 '>
                          <Button
                            className='ms-3'
                            variant="outline-primary"
                            type="button"
                            disabled={revieweeForm.isSubmitting || isSavingAnswers}
                            onClick={() => setShowDeclineModal(true)}
                          >
                            Decline
                          </Button>
                          <Button
                            className='ms-3'
                            variant="outline-primary"
                            type="button"
                            disabled={revieweeForm.isSubmitting || isSavingAnswers}
                            onClick={() => submitRevieweeForm(revieweeForm.values, null, true)}
                          >
                            {isSavingAnswers ? 'Saving...' : 'Save & Finalize later'}
                          </Button>
                          <Button
                            className='ms-3'
                            variant="primary"
                            type="submit"
                            disabled={revieweeForm.isSubmitting || isSavingAnswers}
                          >
                            {revieweeForm.isSubmitting ? 'Submitting...' : 'Submit'}
                          </Button>
                        </Card.Footer>
                      }
                    </FormContext.Provider>
                  </Form>
                  :
                  <EmptyState message={<> No questions available! </>} />
              }
            </>
            :
            <EmptyState message={<> No Review details available! </>} />
      }

      <ConfirmationModal
        title='Submit Review'
        submittingText='Submitting...'
        positiveText='Submit'
        isSubmitting={revieweeForm.isSubmitting}
        show={!!isShowSubmitModal}
        closeOnUserResponse={false}
        onPositiveResponse={() => {
          revieweeForm.submitForm()
        }}
        onNegativeResponse={() => setIsShowSubmitModal(false)}
        onCloseClick={() => setIsShowSubmitModal(false)}
      >
        <p>
          Are you sure you want to submit the review?
        </p>
        <i>Once you submit you can't edit your review.</i>
      </ConfirmationModal>

      {
        <ReasonModalModal
          isSubmitting={isSavingAnswers}
          show={showDeclineModal}
          onClose={() => setShowDeclineModal(false)}
          onSubmit={(formValue) => {
            submitRevieweeForm(revieweeForm.values, null, true, false, true, formValue?.reason)
          }}
        />
      }
    </Card>
  );
};

export default RevieweeFormWidget;