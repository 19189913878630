import { Button, Modal } from '@themesberg/react-bootstrap';
import { EmptyState, Preloader } from 'components';
import { MetaDataContext } from 'contexts';
import { getPerformanceReviewResultDetails } from 'data';
import { IMetadata, IModalProps, IMultiSelectQuestionOptionsTypeConstants, IQuestionTypeConstants, IReviewResult, IReviewResultBlock } from 'interfaces';
import * as React from 'react';
import { finalize } from 'rxjs';
import { toHTML } from 'slack-markdown';
import { MULTI_SELECT_QUESTION_NA_OPTION } from 'teamble-constants';


interface IViewReviewResultModalProps extends IModalProps {
  perfReviewId: string;
}

interface IReviewResultModalBodyProps {
  reviewResult: IReviewResult;
}

interface IAnswerProps {
  answer: IReviewResultBlock;
}

const TextAnswer: React.FunctionComponent<IAnswerProps> = (
  { answer }
) => {
  return (
    <div className='mb-3'>
      <h5> {answer.label} </h5>
      <p style={{ wordBreak: "break-all" }} dangerouslySetInnerHTML={{ __html: toHTML(answer.value, { hrefTarget: '_blank' }) }}></p>
      {/* <p className='mb-1' style={{ whiteSpace: 'pre-line' }} > {answer.value || <i> Not Answered </i>} </p> */}
    </div>
  );
};


const MultiSelectAnswer: React.FunctionComponent<IAnswerProps> = (
  { answer }
) => {

  const metaData = React.useContext(MetaDataContext) as IMetadata;

  const [answerValue, setAnswerValue] = React.useState<string>('');


  React.useEffect(
    () => {

      const multiSelectTypeConstants = metaData.multiSelectQuestionOptionsTypes.constants as IMultiSelectQuestionOptionsTypeConstants;

      if (answer.family === multiSelectTypeConstants.CUSTOM_ITEMS) {

        const choiceLabels: Record<string, string> = {};

        for (const [key, value] of Object.entries((answer as any).choicesValues || {})) {
          choiceLabels[value as string] = key;
        }

        setAnswerValue(choiceLabels[answer.value as string] || answer.value);
      } else {

        if (answer.value === MULTI_SELECT_QUESTION_NA_OPTION.value) {
          setAnswerValue(answer.value);
          return;
        }

        const multiSelectOptions = metaData.multiSelectQuestionOptionsTypes.options.find(
          (option) => option.value === answer.family
        );

        if (multiSelectOptions?.options) {

          const valueOption = multiSelectOptions.options.find(
            (option) => option.value === answer.value
          );

          if (valueOption) {
            setAnswerValue(valueOption.label);
          } else {
            setAnswerValue('');
          }

        } else {
          setAnswerValue('');
        }

      }

    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [answer]
  );

  let label = answer.label as string;

  switch (answer.type) {

    case "objective-question":
      if (answer.isKPI && (answer as any).kpiName) {
        label += ` : ${(answer as any)?.kpiName}`;
      } else if ((answer as any)?.objectiveName) {
        label += ` : ${(answer as any)?.objectiveName}`;
      }
      break;

    case "competency-question":
      if (answer.isBehavior && (answer as any).behaviorTitle) {
        label += ` : ${(answer as any)?.behaviorTitle}`;
      } else if ((answer as any)?.competencyName) {
        label += ` : ${(answer as any)?.competencyName}`;
      }
      break;
  }

  return (
    <div className='mb-3'>
      <h5>
        {label}
        {/* {answer.label} {answer?.isBehavior && (answer as any)?.behaviorTitle ? ` : ${(answer as any)?.behaviorTitle}` : (answer as any)?.competencyName ? ` : ${(answer as any)?.competencyName}` : ''} */}
      </h5>
      <p className='mb-1'> {answerValue || <i> Not Answered </i>} </p>
      {
        answer.commentValue &&
        <>
          <p className='mb-0'> <strong> Comment </strong> </p>
          <p className='mb-1'> {answer.commentValue} </p>
        </>
      }
      {
        answer.comments && answer.comments.map(comment => {
          return (
            <>
              <p className='mb-0'> <strong> {comment.title || "Comment:"} </strong> </p>
              <p className='mb-1'>
                {
                  comment.value
                    ? <p style={{ wordBreak: "break-all" }} dangerouslySetInnerHTML={{ __html: toHTML(comment.value, { hrefTarget: '_blank' }) }}></p>
                    : "n/a"
                }
              </p>
            </>
          )
        })
      }
    </div>
  );
};


const ReviewResultModalBody: React.FunctionComponent<IReviewResultModalBodyProps> = (
  { reviewResult }
) => {

  const metaData = React.useContext(MetaDataContext) as IMetadata;
  const questionTypeConstants: IQuestionTypeConstants = metaData.questionTypes.constants as IQuestionTypeConstants;

  return (
    <>
      {
        reviewResult ?
          (
            <div className='d-flex flex-wrap'>
              <p className='flex-shrink-0 me-3'> <strong> Review: </strong> {reviewResult.title} </p>
              <p className='flex-shrink-0 me-3'> <strong> Reviewer: </strong> {reviewResult.reviewerName} </p>
              <p className='flex-shrink-0 me-3'> <strong> Reviewee: </strong> {reviewResult.revieweeName} </p>
            </div>
          ) :
          (null)
      }
      {
        reviewResult.answers.map(
          (answer) => {

            switch (answer.type) {
              case questionTypeConstants.SHORT_TEXT:
              case questionTypeConstants.LONG_TEXT: {
                return <TextAnswer key={answer.id} answer={answer} />;
              }

              case questionTypeConstants.OBJECTIVE_QUESTION:
              case questionTypeConstants.COMPETENCY_QUESTION:
              case questionTypeConstants.MULTI_SELECT_QUESTION: {
                return <MultiSelectAnswer key={answer.id} answer={answer} />;
              }

              default: {
                return null;
              }

            }

          }
        )
      }
    </>
  )
};


const ViewReviewResultModal: React.FunctionComponent<IViewReviewResultModalProps> = (
  { perfReviewId, show, onClose }
) => {

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [reviewResult, setReviewResult] = React.useState<IReviewResult | null>(null);


  React.useEffect(
    () => {

      setIsLoading(true);
      setReviewResult(null);

      getPerformanceReviewResultDetails(
        perfReviewId
      ).pipe(
        finalize(
          () => setIsLoading(false)
        )
      ).subscribe(
        (response) => {
          setReviewResult(response.data.data);
        }
      );

    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  let showDraft = false;

  if (reviewResult?.prcState === 'Live' && reviewResult.state !== "completed") {
    showDraft = true;
  }

  return (
    <Modal
      as={Modal.Dialog}
      centered
      show={show}
      onHide={onClose}
      size='lg'
    >
      {
        isLoading ?
          (<Preloader show={isLoading} />)
          : (
            <>
              <Modal.Header closeButton >
                <Modal.Title> View Result {showDraft ? '(Draft)' : ''}</Modal.Title>
              </Modal.Header>

              <Modal.Body style={{ height: isLoading || !reviewResult ? '100px' : 'auto' }}>
                <>
                  {
                    reviewResult ?
                      (
                        <ReviewResultModalBody reviewResult={reviewResult} />
                      ) :
                      (
                        <EmptyState message={<>No Result details available.</>} />
                      )
                  }
                </>
              </Modal.Body>

              <Modal.Footer>
                <Button variant="outline-primary" className="ms-auto" onClick={onClose}>
                  Close
                </Button>
              </Modal.Footer>
            </>
          )
      }
    </Modal>
  );
};

export default ViewReviewResultModal;
