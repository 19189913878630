import { Axios, AxiosObservable } from 'axios-observable';
import { MetaDataContext } from 'contexts';
import {
  IActiveReview,
  IActiveReviewAudience,
  IActiveReviewResponse,
  IAnswer,
  IFeedbacks,
  IFileUploadAudience,
  IFileUploadAudienceResponseData,
  IFileUploadNominateAudienceResponseData,
  IGoal,
  IHookResponse,
  ILaunchReviewRequestData,
  IMenuOption,
  IMetadata,
  IMultiSelectQuestionOptionsTypeConstants,
  IReminderRequestData,
  IResponse,
  IReviewAudienceFilters,
  IReviewItemToShare,
  IReviewNominateAudience,
  IReviewResult,
  IReviewTodoAnswerResponse,
  IReviewTodosResponse,
  IRevieweeAnswersRequestData,
  IReviewerRevieweeInfos,
  IReviewerRevieweeInfosRequestData,
  IReviewerRevieweeInfosResponse,
  ISharedPerformanceReview,
  ISimpleMenuOption,
  ISlackWorkspaceUser,
  ReviewStatus,
  SignInWith
} from 'interfaces';
import React, { useContext } from 'react';
import { Subscription, finalize, forkJoin, map } from 'rxjs';
import {
  MULTI_SELECT_QUESTION_NA_OPTION
} from 'teamble-constants';


const getEmptyDataResponse = (response: any) => {
  return {
    ...response,
    data: {
      ...response.data,
      data: []
    }
  };
};


const getReviewerRevieweeInfosResponse =
  (responseData: IReviewerRevieweeInfosResponse[], app: SignInWith): IReviewerRevieweeInfos[] => {

    const options: IReviewerRevieweeInfos[] = responseData.map(
      (option) => {

        if (app === SignInWith.Slack) {
          return {
            reviewee: {
              ...option.reviewee,
              value: option.reviewee.id,
              label: option.reviewee.name,
              description: (option.reviewee as ISlackWorkspaceUser).title,
            },
            reviewer: {
              ...option.reviewer,
              value: option.reviewer.id,
              label: option.reviewer.name,
              description: (option.reviewer as ISlackWorkspaceUser).title,
            }
          };
        } else {
          return {
            reviewee: {
              ...option.reviewee,
              value: option.reviewee.id,
              label: option.reviewee.name,
            },
            reviewer: {
              ...option.reviewer,
              value: option.reviewer.id,
              label: option.reviewer.name,
            }
          };
        }
      }
    );

    return options;

  };


export const getReviewTodoAnswers =
  (answers: IAnswer[], metadata: IMetadata): IAnswer[] => {

    const multiQuestionTypeConstants = metadata.multiSelectQuestionOptionsTypes.constants as IMultiSelectQuestionOptionsTypeConstants;
    const multiQuestionTypeOptions = metadata.multiSelectQuestionOptionsTypes.options;

    return answers.map(
      (answer) => {

        if (['choice', 'competency-question', 'objective-question'].includes(answer.type || '')) {
          if (answer?.selectedView?.value === 'dropdown') {
            answer.dropdown = true;
          }

          if (answer.family === multiQuestionTypeConstants.CUSTOM_ITEMS) {

            if (!answer.dropdown) {
              return answer;
            } else {

              return {
                ...answer,
                multiSelectValue: answer.value
                  ?
                  {
                    value: answer.value,
                    label: answer.value
                  }
                  :
                  undefined
              };
            }
          } else {

            const multiSelectFamily = multiQuestionTypeOptions.find(
              (option) => option.value === answer.family
            );
            const selectedOption = multiSelectFamily?.options?.find(
              (option) => option.value === answer.value
            );

            if (!answer.dropdown) {

              if (!answer.value) {
                return answer;
              }

              return {
                ...answer,
                value: selectedOption ? selectedOption?.label : answer.isNotApplicableOption ? MULTI_SELECT_QUESTION_NA_OPTION.label : undefined
              };
            } else {

              if (!answer.value) {
                return {
                  ...answer,
                  multiSelectValue: undefined
                };
              }

              const ans = {
                ...answer,
                multiSelectValue: selectedOption ? selectedOption : answer.isNotApplicableOption ? MULTI_SELECT_QUESTION_NA_OPTION : undefined
              };
              return ans;
            }

          };
        }

        return answer;
      }
    );
  };


export const launchNewReview = (data: ILaunchReviewRequestData, id?: string): AxiosObservable<IResponse<null>> => {
  return Axios.post<IResponse<null>>('/launch-performance-review-campaign', {
    ...data,
    id
  });
};


export const saveReviewDraft =
  (data: Partial<ILaunchReviewRequestData>, reviewId?: string): AxiosObservable<IResponse<IActiveReviewResponse>> => {
    return Axios.post<IResponse<IActiveReviewResponse>>('/save-performance-review-campaign', {
      ...data,
      id: reviewId
    });
  };


export const addUsersToActiveReview = (
  app: SignInWith,
  data: IReviewerRevieweeInfosRequestData[],
  channelOrWorkspaceId: string,
  perfReviewCampaignId: string,
) => {

  const requestData: any = {
    reviewerRevieweeInfos: data,
    perfReviewCampaignId
  };

  if (app === SignInWith.Slack) {
    requestData.workspaceId = channelOrWorkspaceId;
  } else if (app === SignInWith.MsTeams) {
    requestData.channelId = channelOrWorkspaceId;
  }

  return Axios.post<IResponse<null>>('/add-users-launched-performance-review-campaign', requestData);
};


export const finalizeReview = (perfReviewCampaignId: string) => {

  return Axios.post<IResponse<null>>('/close-performance-review-campaign', {
    perfReviewCampaignId
  });
};


export const sendRemindersToAllAudiencePairs = (
  reminderNoteFormValues: IReminderRequestData,
  perfReviewCampaignId: string
) => {

  return Axios.post<IResponse<void>>(
    '/send-remainder-to-all-reviewers',
    {
      ...reminderNoteFormValues,
      perfReviewCampaignId
    }
  );
};


export const sendRemindersToOneReviewer = (
  reminderNoteFormValues: IReminderRequestData,
  perfReviewId: string
) => {

  return Axios.post<IResponse<void>>(
    '/send-remainder-to-one-reviewer',
    {
      ...reminderNoteFormValues,
      perfReviewId
    }
  );
};

export const sendNominationReminder = (
  reminderNoteFormValues: IReminderRequestData,
  id: string,
  state: string
) => {

  return Axios.post<IResponse<void>>(
    "/nomination-reminder",
    {
      ...reminderNoteFormValues,
      state,
      id
    }
  );
};



export const getPerformanceReviewResultDetails = (
  perfReviewId: string,
): AxiosObservable<IResponse<IReviewResult>> => {

  return Axios.post<IResponse<IReviewResult>>('/detail-performance-review', {
    perfReviewId
  });

};


export const getActiveReviews = (
  states: ReviewStatus[],
  filterLaunchedBy: string[] = [],
  filterByUserType: string[] = [],
  filterByUserTypeIds: string[] = [],
): AxiosObservable<IResponse<IActiveReview[]>> => {
  return Axios.post<IResponse<IActiveReview[]>>(
    '/list-performance-review-campaign-v2',
    { states, filterByUserType, filterByUserTypeIds, filterLaunchedBy }
  );
};


export const getReviewsByState = (states: string[]): AxiosObservable<IResponse<IMenuOption[]>> => {

  return Axios.post<IResponse<IActiveReview[]>>(
    '/list-performance-review-campaign-by-states',
    { states }
  ).pipe(
    map(
      (response) => {

        const options: IMenuOption[] = response.data.data.map(
          (option) => ({
            value: option.id,
            label: option.title
          })
        );

        return {
          ...response,
          data: {
            ...response.data,
            data: options
          }
        };

      }
    )
  );

};


export const getActiveReviewById = (activeReviewId: string): AxiosObservable<IResponse<IActiveReview>> => {
  return Axios.post<IResponse<IActiveReviewResponse>>(
    '/detail-performance-review-campaign',
    {
      perfReviewCampaignId: activeReviewId
    }
  ).pipe(
    map(
      (response) => {

        const audience: IActiveReviewAudience[] = response.data.data.reviewerRevieweeInfos.map(
          (info) => ({
            ...info,
            reviewee: {
              ...info.reviewee,
              value: info.reviewee.id,
              label: info.reviewee.name,
              description: info.reviewee.title,
            },
            reviewer: {
              ...info.reviewer,
              value: info?.reviewer?.id,
              label: info?.reviewer?.name,
              description: info?.reviewer?.title,
            }
          })
        );

        const verifiedEmailPairs: Array<IReviewNominateAudience> =
          response?.data?.data?.nominatorAudience?.map((data: IReviewNominateAudience) => ({
            ...data,
            lable: data?.email,
            value: data.id,
            label: data.name,
          }))

        const unVerifiedEmailPairs: Array<IReviewNominateAudience> = []

        const newPayload = {
          ...response.data.data,
          reviewerRevieweeInfos: audience,
          nominatorAudience: {
            verifiedEmailPairs,
            unVerifiedEmailPairs,
          }
        };

        return {
          ...response,
          data: {
            ...response.data,
            data: newPayload
          }
        };

      }
    )
  );
};


export const downloadExcelReportOfReview = (
  perfReviewCampaignId: string,
) => {

  return Axios.post<Blob>(
    '/report-performance-review-campaign',
    { perfReviewCampaignId },
    {
      params: { downloadExcel: true },
      responseType: 'arraybuffer'
    }
  );
};


export const getAutoSuggestAudiencePairs =
  (app: SignInWith, channelOrWorkspaceId: string, filters: IReviewAudienceFilters):
    AxiosObservable<IResponse<IReviewerRevieweeInfos[]>> => {

    return Axios.post<IResponse<IReviewerRevieweeInfosResponse[]>>(
      "/auto-suggest-users",
      app === SignInWith.Slack ? { workspaceId: channelOrWorkspaceId, ...filters } : { channelId: channelOrWorkspaceId, ...filters }
    ).pipe(
      map(
        (response) => {

          if (!response.data.data?.length) {
            return getEmptyDataResponse(response);
          }

          const options: IReviewerRevieweeInfos[] =
            getReviewerRevieweeInfosResponse(response.data.data, app);

          return {
            ...response,
            data: {
              ...response.data,
              data: options
            }
          };

        }
      )
    )
  };


export const getAudiencePairsFromFile =
  (app: SignInWith, formData: FormData): AxiosObservable<IResponse<IFileUploadAudience>> => {

    return Axios.post<IResponse<IFileUploadAudienceResponseData>>(
      "/get-audience-pairs",
      formData,
    ).pipe(
      map(
        (response) => {

          const verifiedEmailPairs: IReviewerRevieweeInfos[] =
            getReviewerRevieweeInfosResponse(response.data.data.verifiedEmailPairs, app);

          const unVerifiedEmailPairs: IReviewerRevieweeInfos[] =
            getReviewerRevieweeInfosResponse(response.data.data.unVerifiedEmailPairs, app);

          return {
            ...response,
            data: {
              ...response.data,
              data: {
                ...response.data.data,
                verifiedEmailPairs,
                unVerifiedEmailPairs
              }
            }
          };

        }
      )
    );
  };

export const getNominateAudienceFromFile =
  (formData: FormData): AxiosObservable<IResponse<IFileUploadNominateAudienceResponseData>> => {

    return Axios.post<IResponse<any>>(
      "/nomination-audience",
      formData,
    ).pipe(
      map(
        (response) => {

          const verifiedEmailPairs: Array<ISlackWorkspaceUser> =
            response.data.data.verifiedEmailPairs.map((data: ISlackWorkspaceUser) => ({
              ...data,
              lable: data?.email,
              value: data.id,
              label: data.name,
              description: (data as ISlackWorkspaceUser).title,
            }))

          const unVerifiedEmailPairs: Array<ISlackWorkspaceUser> =
            response.data.data.unVerifiedEmailPairs.map((data: ISlackWorkspaceUser) => ({
              ...data,
              lable: data?.email,
              value: data.id,
              label: data.name,
              description: (data as ISlackWorkspaceUser).title,
            }))

          return {
            ...response,
            data: {
              ...response.data,
              data: {
                ...response.data.data,
                verifiedEmailPairs,
                unVerifiedEmailPairs
              }
            }
          };

        }
      )
    );
  };

export const updateNominateAudience = (campaignId: string, type: string, audience: IReviewNominateAudience[]): AxiosObservable<IResponse<void>> => {
  return Axios.post<IResponse<void>>(
    "/nomination-audience-actions",
    { campaignId, type, audience }
  );
};

export const getManagerUpwardDownwardAudiencePairs = (
  app: SignInWith,
  channelOrWorkspaceId: string,
  type: 'upward' | 'downward',
  filters: IReviewAudienceFilters
):
  AxiosObservable<IResponse<IReviewerRevieweeInfos[]>> => {

  return Axios.post<IResponse<IReviewerRevieweeInfosResponse[]>>(
    '/manager-upward-downward-pair',
    {
      [app === SignInWith.Slack ? 'workspaceId' : 'channelId']: channelOrWorkspaceId,
      type,
      ...filters
    }
  ).pipe(
    map(
      (response) => {

        if (!response.data.data?.length) {
          return getEmptyDataResponse(response);
        }

        const options: IReviewerRevieweeInfos[] =
          getReviewerRevieweeInfosResponse(response.data.data, app);

        return {
          ...response,
          data: {
            ...response.data,
            data: options
          }
        };

      }
    )
  )
};


export const startPerformanceReviewNomination =
  (campingId: string, workspaceId: string, initialCheck: boolean):
    AxiosObservable<IResponse<ISlackWorkspaceUser[]>> => {

    return Axios.post<IResponse<ISlackWorkspaceUser[]>>(
      "/start-performance-review-nominations",
      { campingId, workspaceId, initialCheck }
    );

  };


export const cancelPerformanceReviewNomination = (
  campaignId: string
): AxiosObservable<IResponse<void>> => {

  return Axios.post<IResponse<void>>(
    "/performance-review/cancel-performance-review-nomination",
    { campaignId }
  );
};


export const submitRevieweeAnswers = (data: IRevieweeAnswersRequestData): AxiosObservable<IResponse<IReviewTodoAnswerResponse>> => {

  return Axios.post<IResponse<IReviewTodoAnswerResponse>>(
    `/submit-performance-review-answers`,
    data
  );

};


export const getReviewTodosData = (): AxiosObservable<IResponse<IReviewTodosResponse>> => {

  return Axios.post<IResponse<IReviewTodosResponse>>(
    '/list-performance-reviews',
    {}
  )
};

const getReviewDetails = (perfReviewId: string, fromTodo = true): AxiosObservable<IResponse<IReviewTodoAnswerResponse>> => {

  return Axios.post<IResponse<IReviewTodoAnswerResponse>>(
    '/detail-performance-review',
    { perfReviewId, fromTodo }
  )
};

interface IReviewHookResponse<T, U = any> {
  data?: T;
  isLoading: boolean;
  onDataUpdate?: any;
  likedReviewResponse?: IAnswer[][] | undefined
  dispatchData?: React.Dispatch<U>;
  refetchData?: () => void;
}

export const useReviewTodoDetailsData = (perfReviewId?: string, fromTodo = true): IReviewHookResponse<IReviewTodoAnswerResponse> => {

  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [reviewTodoDetails, setReviewTodoDetails] = React.useState<IReviewTodoAnswerResponse>();
  const [likedReviewResponse, setLinkedReviewResponse] = React.useState<IAnswer[][]>();
  const metadata = useContext(MetaDataContext) as IMetadata;


  const getReviewTodoDetailsData = (): Subscription | undefined => {
    if (!perfReviewId) {
      setReviewTodoDetails(undefined);
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    setReviewTodoDetails(undefined);

    getReviewDetails(perfReviewId)
      .pipe(finalize(() => {
        setIsLoading(false)
      }))
      .subscribe((response) => {
        const data = response.data.data;
        data.answers = getReviewTodoAnswers(data.answers, metadata);
        setReviewTodoDetails(data);
      });
  };

  React.useEffect(
    () => {

      if (!perfReviewId) {
        setReviewTodoDetails(undefined);
        setIsLoading(false);
        return;
      }

      setIsLoading(true);
      setReviewTodoDetails(undefined);

      const subscription = getReviewDetails(perfReviewId, fromTodo)
        .pipe(
          finalize(() => setIsLoading(false))
        ).subscribe(
          (reviewsResponse) => {

            const reviewDetails = reviewsResponse.data.data;
            reviewDetails.answers = getReviewTodoAnswers(reviewDetails.answers, metadata);

            const answers = [];
            const linkedReviews = (reviewDetails as any).linkedReviews || [];

            // linked reviews
            for (let index = 0; index < linkedReviews.length; index++) {
              const d = linkedReviews[index];
              const ans = getReviewTodoAnswers(d, metadata);
              answers.push(ans)
            }

            setLinkedReviewResponse(answers);
            setReviewTodoDetails(reviewDetails);
          }
        );

      return () => subscription.unsubscribe();

    }, [metadata, perfReviewId]);

  const handleDataUpdate = (updatedData: IReviewTodoAnswerResponse | undefined): void => {

    if (!updatedData) {
      setReviewTodoDetails(updatedData);
      return;
    }

    updatedData.answers = getReviewTodoAnswers(
      updatedData.answers,
      metadata
    );
    setReviewTodoDetails(updatedData);
  };


  return {
    data: reviewTodoDetails,
    refetchData: getReviewTodoDetailsData,
    likedReviewResponse: likedReviewResponse,
    onDataUpdate: handleDataUpdate,
    isLoading
  };

};


export const getRevieweeGoals =
  (revieweeUserId: string, perfReviewId: string): AxiosObservable<IResponse<IGoal[]>> => {

    return Axios.post<IResponse<IGoal[]>>(
      "/list-user-goals",
      { revieweeUserId, perfReviewId }
    );
  };


export const getRevieweeFeedbacks = (perfReviewId: string): AxiosObservable<IResponse<IFeedbacks>> => {
  return Axios.post<IResponse<IFeedbacks>>(
    "/list-performance-review-feedbacks",
    // Big todo update this to teambleTeamId at all places
    { perfReviewId }
  );
};

export const getSharedPerformanceReviews = (): AxiosObservable<IResponse<ISharedPerformanceReview>> => {
  return Axios.post<IResponse<ISharedPerformanceReview>>(
    "/shared-performance-reviews"
  );
};

export const getSharedReportsPerformanceReviews = (): AxiosObservable<IResponse<IReviewItemToShare[]>> => {
  return Axios.post<IResponse<IReviewItemToShare[]>>(
    "/shared-with-me-performance-reviews"
  );
};


export const removeRevieweePairs = (perfReviewIds: string[], perfReviewCampaignId: string) => {

  return Axios.post<IResponse<null>>(
    "/remove-reviewer-reviewee-pairs", {
    perfReviewIds,
    perfReviewCampaignId
  });
};


export const removeReview = (campaignId: string) => {

  return Axios.post<IResponse<void>>(
    "/remove-performance-review-campaign", {
    campaignId
  });
};


export const reopenReview = (campaignId: string) => {

  return Axios.post<IResponse<void>>(
    "/reopen-performance-review-campaign", {
    campaignId
  });
};


export const removeReviewSchedule = (campaignId: string) => {

  return Axios.post<IResponse<void>>(
    "/remove-performance-review-schedule", {
    campaignId,
  });
};


export const reopenReviewAudiencePair = (perfReviewId: string) => {

  return Axios.post<IResponse<void>>(
    "/reopen-performance-review",
    { perfReviewId }
  );
};

export const changeReviewerAudiencePair = (perfReviewId: string, reviewerId: string, retainValues: boolean,) => {

  return Axios.post<IResponse<void>>(
    "/change-performance-review-reviewer",
    { perfReviewId, reviewerId, retainValues }
  );
};



export const cancelReviewCampaign =
  (campaignId: string): AxiosObservable<IResponse<void>> => {

    return Axios.post<IResponse<void>>(
      "/performance-review/cancel-performance-review-campaign",
      { campaignId }
    );
  };